import { Tab, Tabs } from "@mui/material";
import styled from "styled-components/macro";
import Search from "../../assets/images/mobile-search.svg";

export const TabHolder = styled(Tabs)`
  && {
    z-index: 9999;
    color: white;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    position: fixed;
    /* margin: auto; */
    height: 70px;
    padding-top: 0;
    margin-left: clamp(150px, 15vw, 200px);
    top: 0;
    .MuiTabs-indicator {
      background-color: ${(props) =>
        props.loggedin === "true" ? "#ffcc66" : "#fff"};
      height: 4px;
    }
    div {
      display: flex;
      justify-content: center;
    }

    .MuiTab-textColorInherit {
      opacity: unset;
    }

    .MuiTabs-indicator {
      background-color: #f8c35e; /* Yellow underline color */
      height: 4px;
      transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
    }

   
  }
`;
export const Provider = styled(Tab)`
  && {
    display: flex;
    flex-direction: row;
    text-transform: none;
    font-size: min(max(12px, 2vw), 22px);
    padding: 0;
    margin: 0 10px;
    min-width: unset;
    color: ${(props) =>
      props.focused === "focused" ? "white" : "rgba(225, 225, 225, 0.7)"};

    &:hover {
      color: white;
      opacity: 1;
    }
    width: fit-content;
    padding: 0;
    font-family: "Avenir", "Avenir-Condensed";
    font-weight: 900;

    @media (min-width: ${(props) => props.loggedin === "true" ? "1080px" : "1300px"}) {
      margin: 0 20px;
    };

    .Mui-selected {
      margin-top: 0px;
    }

    ${(props) =>
      props.issearch === 'true' &&
      `
      &::before {
        content: url(${Search});
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
        margin-top: 5px;

      }
      @media (max-width: 1079px) {
        &::before {
          display: none;
        }
      }

      &:hover::before {
        filter: brightness(0) invert(1);
      }
    `}

    /* Focused state styles */
    ${(props) =>
      props.focused === "focused" &&
      `
      font-weight: bold;

      &::before {
        filter: brightness(0) invert(1);
      }
    `}

    /* &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: ${(props) => (props.focused === "focused" ? "100%" : "0")};
      height: 4px;
      background-color: #F8C35E; 
      transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
      border-radius: 2px;
    } */
  }
`;
export const CustomerMessage = styled.div`
  height: 22px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  height: 102px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(12px, 2.5vw, 22px);
  margin-bottom: 40px;
  position: fixed;
  right: 0;
  top: 70px;
  width: 100%;
  z-index: 9;
`;

export const SlidingUnderline = styled.div`
  position: absolute;
  bottom: 0;
  height: 4px;
  background-color: #f8c35e;
  border-radius: 2px;
  transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
`;