import React, { lazy, Suspense } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { AppRoutes } from "../app/routes";

// PAGES
import Home from "../pages/Home";
import Nav from "../features/components/Nav";
import SigninCallback from "../pages/account/signin/SigninCallback";
import { AppLoading } from "../theme";
import RingLoader from "react-spinners/RingLoader";
// import UserDetector from "./features/detectors/UserDetector";
import LinkDetector from "../features/detectors/LinkDetector";
import { Roles } from "../features/user/userTypes";
import RouteGuard from "../utils/routerGuard";
// import PaymentStatus from "./pages/PaymentStatus";
import ErrorBoundary from "../features/components/ErrorBoundry";
import { useUser } from "../features/user/useUser";
import ChannelTable from "../admin/ChannelTable";
import HomeRedirector from "./HomeRedirect";

const Footer = React.lazy(() => import("../features/components/Footer"));

const JoinClearplay = React.lazy(() => import("../pages/JoinClearplay"));
const AdminDashboard = React.lazy(() => import("../admin/AdminDashboard"));
const AssetDetailTable = React.lazy(() => import("../admin/AssetDetailTable"));
const AccountManagement = React.lazy(() => import("../admin/AccountManagement"));
const CheckYourEmail = React.lazy(
  () => import("../pages/account/myAccount/CheckYourEmail")
);
const SignupPassword = React.lazy(
  () => import("../pages/account/signup/SignupPassword")
);
const CollectEmail = React.lazy(
  () => import("../pages/account/signin/CollectEmail")
);
const EnterEmail = React.lazy(
  () => import("../pages/account/signin/EnterEmail")
);
const EnterPassword = React.lazy(
  () => import("../pages/account/signin/EnterPassword")
);
const SignupEmail = React.lazy(
  () => import("../pages/account/signup/SignupEmail")
);
const NewEmail = React.lazy(() => import("../pages/account/myAccount/NewEmail"));
const NewPassword = React.lazy(
  () => import("../pages/account/myAccount/NewPassword")
);
const MoviePage = React.lazy(() => import("../pages/MoviePage"));
const SearchPage = React.lazy(() => import("../pages/AlgoliaSearchWrapper"));
const AdminHome = React.lazy(() => import("../admin/AdminHome"));
const AdminLinks = React.lazy(() => import("../admin/AdminLinks"));

const noSpinnerRoute = [
  AppRoutes.CONFIRMATION_SCREEN,
  AppRoutes.HOME,
  AppRoutes.MOBILE_EXPLAINER,
  AppRoutes.SEARCH,
  AppRoutes.ADMIN_DASHBOARD,
];

export const AppRouter: React.FC = () => {
  const { userLoading } = useUser();
  const loading = userLoading;

  return (
    <Router>
      <Suspense
        fallback={
          <AppLoading
            active={true}
            spinner={<RingLoader color={"#66ccff"} />}
          />
        }
      >
        <ErrorBoundary>
          <LinkDetector children={null} />
          <Nav />
          <AppLoading
            id="global-loader"
            active={
              loading &&
              !noSpinnerRoute.includes(window.location.pathname as AppRoutes)
            }
            spinner={<RingLoader color={"#66ccff"} />}
          />
          <Switch>
          <Route
  exact
  path={AppRoutes.HOME}
  render={(props: any) => <HomeRedirector {...props} />}
/>
            <Route exact path="/disney" component={Home} />
            <Route exact path="/amazon" component={Home} />
            <Route exact path="/apple" component={Home} />
            <Route exact path="/max" component={Home} />
            <Route exact path="/netflix" component={Home} />
            <Route exact path="/search" component={Home} />
            <Route exact path={AppRoutes.HOME_EXPLORE} component={Home} />
            {/* <Route exact path={AppRoutes.MOVIE_PAGE} component={MoviePage} /> */}
            <Route
              exact
              path={AppRoutes.JOIN_CLEARPLAY}
              component={JoinClearplay}
            />

            {/* <Route
                exact
                path={AppRoutes.SEARCH}
                render={() => {
                  return <SearchPage />;
                }}
              /> */}
            <Route exact path={AppRoutes.ENTER_EMAIL} component={EnterEmail} />
            <Route
              exact
              path={AppRoutes.ENTER_PASSWORD}
              component={EnterPassword}
            />
            <Route
              exact
              path={AppRoutes.SIGNUP_EMAIL}
              component={SignupEmail}
            />

            <Route
              exact
              path={AppRoutes.SIGNUP_PASSWORD}
              component={SignupPassword}
            />
            <Route
              exact
              path={AppRoutes.ABANDONED_CHECKOUT}
              component={lazy(
                () => import("../pages/account/signup/AbandonedCheckout")
              )}
            />
            <Route path="/account-membership-payment">
              <Redirect to="/my-account" />
            </Route>
            <Route
              path={AppRoutes.SIGNUP_PAYMENT} >
              <Redirect to="/my-account" />
              </Route>
            <Route
              exact
              path={AppRoutes.MY_ACCOUNT}
              component={lazy(
                () => import("../pages/account/myAccount/MyAccount")
              )}
            />
            <Route
              exact
              path={AppRoutes.CHECK_EMAIL}
              component={CheckYourEmail}
            />
            <Route exact path={AppRoutes.NEW_EMAIL} component={NewEmail} />
            <Route
              exact
              path={AppRoutes.NEW_PASSWORD}
              component={NewPassword}
            />
            <Route
              exact
              path={AppRoutes.CONFIRMATION_SCREEN}
              component={lazy(
                () => import("../pages/account/myAccount/ConfirmationScreen")
              )}
            />
            <Route
              exact
              path={AppRoutes.ACCOUNT_FOUND}
              component={lazy(
                () => import("../pages/account/signin/AccountFound")
              )}
            />
            <Route
              exact
              path={AppRoutes.COLLECT_EMAIL}
              component={CollectEmail}
            />
            <Route
              exact
              path={AppRoutes.MOBILE_EXPLAINER}
              component={lazy(() => import("../pages/MobileExplainer"))}
            />
            {/* <Route
                exact
                path={AppRoutes.MEMBERSHIP_AND_PAYMENT}
                component={lazy(
                  () => import("./pages/account/signup/StripeForm")
                )}
              /> */}
            <Route
              exact
              path={AppRoutes.ADMIN_MANAGE_SHELF}
              component={lazy(() => import("../admin/ManageShelfPage"))}
            />
            <Route
              exact
              path={AppRoutes.ADMIN_TEST_ACCOUNTS}
              component={lazy(() => import("../admin/AdminTestAccountCreation"))}
            />

            <Route
              exact
              path={AppRoutes.ASSET_DETAIL_TABLE}
              render={(props: any) => (
                <RouteGuard
                  allowedRoles={[Roles.ADMIN]}
                  render={(guardProps: any) => (
                    <AssetDetailTable {...guardProps} {...props} />
                  )}
                />
              )}
            />
            <Route
              exact
              path={AppRoutes.ADMIN_DASHBOARD}
              render={(props: any) => (
                <RouteGuard
                  allowedRoles={[Roles.ADMIN, Roles.FILTER_SUPPORT]}
                  render={(guardProps: any) => (
                    <AdminDashboard {...guardProps} {...props} />
                  )}
                />
              )}
            />
            <Route
              exact
              path={AppRoutes.ADMIN_PLATFORM_CHANELS}
              render={(props: any) => (
                <RouteGuard
                  allowedRoles={[Roles.ADMIN, Roles.FILTER_SUPPORT]}
                  render={(guardProps: any) => (
                    <ChannelTable {...guardProps} {...props} />
                  )}
                />
              )}
            />
            <Route
              exact
              path={AppRoutes.ADMIN_HOME}
              render={(props: any) => (
                <RouteGuard
                  allowedRoles={[
                    Roles.ADMIN,
                    Roles.SUPPORT,
                    Roles.FILTER_SUPPORT,
                    Roles.CUSTOMER_SUPPORT,
                  ]}
                  render={(guardProps: any) => (
                    <AdminHome {...guardProps} {...props} />
                  )}
                />
              )}
            />
            <Route
              exact
              path={AppRoutes.ADMIN_LINKS}
              render={(props: any) => (
                <RouteGuard
                  allowedRoles={[Roles.ADMIN]}
                  render={(guardProps: any) => (
                    <AdminLinks {...guardProps} {...props} />
                  )}
                />
              )}
            />
            <Route
              exact
              path={AppRoutes.ADMIN_ACCCOUNT_MANAGEMENT}
              render={(props: any) => (
                <RouteGuard
                  allowedRoles={[
                    Roles.ADMIN,
                    Roles.SUPPORT,
                    Roles.CUSTOMER_SUPPORT,
                  ]}
                  render={(guardProps: any) => (
                    <AccountManagement {...guardProps} {...props} />
                  )}
                />
              )}
            />
            <Route
              exact
              path={AppRoutes.SIGNIN_CALLBACK}
              component={SigninCallback}
            />
            {/* <Route
                  exact
                  path={AppRoutes.PAYMENT_STATUS}
                  component={PaymentStatus}
                /> */}
            <Route>
              <Redirect to={AppRoutes.HOME}></Redirect>
              <Home />
            </Route>
          </Switch>
          <Footer />
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
};
