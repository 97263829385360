import { useEffect, useState, memo, useRef } from "react";
import SearchImage from "../features/components/SearchImage";
import {
  getLocalStorageItem,
  LocalStorage,
  setLocalStorageItem,
} from "../utils/localStorageService";
import { ResultsWrapper } from "./SearchPage.styles";
import TagManager from "react-gtm-module";
import SearchInputComponent from "../features/components/SearchInputComponent";
import { DeviceType } from "../features/user/userTypes";
import {
  userDeviceSiganl,
  userLoadingSignal,
} from "../features/user/userSignals";
import { Configure, useInfiniteHits } from "react-instantsearch";
import { ContentLoading } from "../theme";
import { PulseLoader } from "react-spinners";

const SearchPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const isMobile = userDeviceSiganl.value !== DeviceType.DESKTOP;
  const userToken = getLocalStorageItem(LocalStorage.CLEARPLAY_SEARCH_TOKEN);
  const isMounted = useRef(false);

  const { isLastPage, showMore } = useInfiniteHits();
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  useEffect(() => {
    if (userLoadingSignal.value && !isMounted.current) return;
    const search: any = document.getElementById("search-input");
    search?.focus();
  }, [userLoadingSignal]);

  useEffect(() => {
    if (!userToken) {
      const randomToken = Math.floor(Math.random() * Date.now());
      setLocalStorageItem(
        LocalStorage.CLEARPLAY_SEARCH_TOKEN,
        randomToken.toString()
      );
    }
    if (userToken) {
      TagManager.dataLayer({
        dataLayer: {
          algoliaUserToken: userToken,
        },
      });
    }
  }, [userToken]);

  useEffect(() => {
    if (!isMounted.current) return;
    var observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const added: any = mutation.addedNodes;
        const isToolTip = added.length && added[0].id === "fm-tt";
        if (mutation.addedNodes.length && !isToolTip) window.scrollTo(0, 0);
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
      attributeOldValue: true,
      characterDataOldValue: true,
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div style={isMobile ? { paddingTop: "25px" } : {}}
    >
      <SearchInputComponent isMobile={isMobile} />
      <div data-insights-index={"assets"}>
        <Configure clickAnalytics={true} userToken={userToken!} />
        <ResultsWrapper
          mobile={isMobile ? 1 : 0}
          hitComponent={({ hit }: any) => {
            hit.__position === 1 &&
              TagManager.dataLayer({ dataLayer: { event: "Hits Viewed" } });
            return (
              <div
                data-insights-object-id={hit.objectID}
                data-insights-position={hit.__position}
                data-insights-query-id={hit.__queryID}
              >
                <SearchImage
                  film={hit}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            );
          }}
        />
        <div ref={sentinelRef} />
        <ContentLoading
          active={!isLastPage}
          spinner={<PulseLoader color={"#66ccff"} />}
        />
      </div>
    </div>
  );
};

export default memo(SearchPage);
