import React, { useState, useEffect } from "react";
import { StyledSearchBox } from "./SearchInputComponent.styles";
import { useHistory } from "react-router-dom";
import { SearchBox, useSearchBox } from "react-instantsearch";
import "./CustomSearchBox.scss";

interface CustomSearchBoxProps {
  autoFocus?: boolean;
  placeholder?: string;
  initialQuery: string;
  isMobile: boolean;
}

const CustomSearchBox: React.FC<CustomSearchBoxProps> = ({
  autoFocus,
  placeholder,
  initialQuery,
  isMobile,
}) => {
  const searchBox = useSearchBox();
  const [initialSearch, setInitialSearch] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!initialSearch && initialQuery) {
      setInitialSearch(true);
      searchBox.query = initialQuery;
      searchBox.refine(initialQuery);
    }
  }, [initialQuery, initialSearch, searchBox]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchBox.query) {
      searchParams.set("q", searchBox.query);
    } else {
      searchParams.delete("q");
    }
    const newPath = `${
      searchParams.toString() ? `/search?${searchParams.toString()}` : "/search"
    }`;
    history.push(newPath);
  }, [history, searchBox.query]);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById("search-input");
      if (el) {
        (el as any).children[0][0].value = searchBox.query;
      }
    }, 600);
  }, []);

  console.log("isMobile :>> ", isMobile);

  return (
    <SearchBox
      className={isMobile ? "search-input-mobile" : ""}
      autoCorrect="off"
      id="search-input"
      autoFocus={autoFocus}
      placeholder={placeholder}
    />
  );
};

export default CustomSearchBox;
