import { AxiosError } from "axios";
import { apiRequest, API_TYPE, HTTP_METHOD } from "../../../app/api";
import { AdminAsset } from "../tables/tableTypes";
import { ConstructAssetDetail } from "./assetModel";
import { MovieScoreAsset } from "./assetTypes";
import { movieDBSignal, releasedAssetsSignal } from "./useAssetData";
import { userMessageSignal } from "../../user/userSignals";

const TMDB = "7c7094b555f355c33658f8809041f462";

export const updateScore = async (options: Partial<MovieScoreAsset>) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/assets/scores`,
      options
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const addScore = async (options: Partial<MovieScoreAsset>) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/scores`,
      options
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const deleteScore = async (options: Partial<MovieScoreAsset>) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.DELETE,
      API_TYPE.FILTER_API,
      `/admin/assets/scores`,
      options
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const getAssetDetail = async (imdbId: string) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/scores/search`,
      { imdb_id: imdbId }
    );
    return ConstructAssetDetail(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const patchAssetDetails = async (opts: {
  options: Partial<AdminAsset>;
  type: "shows" | "movies";
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/assets/${opts.type}`,
      opts.options
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const fetchMovieListDetails = async () => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/assets/movies`
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const patchShowDetail = async (options: Partial<AdminAsset>) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.PATCH,
      API_TYPE.FILTER_API,
      `/admin/assets/shows`,
      options
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const fetchMovieDatabaseAsset = async (imdbId: string) => {
  try {
    const response = await fetch(
      `https://api.themoviedb.org/3/find/${imdbId}?api_key=${TMDB}&language=en-US&external_source=imdb_id`
    );
    const jsonData = await response.json();
    movieDBSignal.value = jsonData;
    return;
  } catch (error) {
    const axiosError = error as AxiosError;
    userMessageSignal.value = {
      message: "Error Re-indexing please try again",
      color: "red",
    };
    throw axiosError;
  }
};

export const reIndexAsset = async (opts: {
  indexType: string;
  recordToIndex: Record<string, any>;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/${opts.indexType}`,
      opts.recordToIndex
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const addShelf = async (opts: {
  name: string;
  description: string;
  isActive: boolean;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves`,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    userMessageSignal.value = {
      message: "Error adding shelf please try again.",
      color: "red",
    };
    throw axiosError;
  }
};

export const copyEpisodes = async (opts: {
  destination_show_asset_id: number;
  source_episode_asset_id: number;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/copyEpisodes? `,
      opts
    );
    if (data === "") 
    return { success: true };
  } catch (error) {
    const axiosError = error as AxiosError;
    userMessageSignal.value = {
      message: "Error copying episode, please try again.",
      color: "red",
    };
    throw axiosError;
  }
};

export const copySeries = async (opts: {
  destination_platform_id: number;
  source_show_asset_id: number;
  name_suffix: string;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/copyShows? `,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    userMessageSignal.value = {
      message: "Error copying series, please try again.",
      color: "red",
    };
    throw axiosError;
  }
};

export const fetchSeriesToCopyList = async (opts: {
  platformId: number;
  imdbId: string;
}) => {
  try {
    let data;
    if (opts.imdbId != null) {
      data = await apiRequest(
        HTTP_METHOD.GET,
        API_TYPE.FILTER_API,
        `/admin/assets/shows/index?platform_id=${opts.platformId}&imdb_id=${opts.imdbId}& `
      );
    } else {
      data = await apiRequest(
        HTTP_METHOD.GET,
        API_TYPE.FILTER_API,
        `/admin/assets/shows/index?platform_id=${opts.platformId}& `
      );
    }
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    userMessageSignal.value = {
      message: "Something went wrong, please try again.",
      color: "red",
    };
    throw axiosError;
  }
};

export const removeAssetFromShelf = async (opts: { asset_list_id: number }) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.DELETE,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves/assets? `,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const addAssetToShelf = async (opts: {
  asset_id: number;
  asset_list_type_id: number;
  is_active: boolean;
}) => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      `/admin/assets/shelves/assets? `,
      opts
    );
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    userMessageSignal.value = {
      message: "Error Adding Asset",
      color: "red",
    };
    throw axiosError;
  }
};

export const fetchReleasedAssets = async () => {
  try {
    const data = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/admin/assets/released? `
    );
    releasedAssetsSignal.value = data;
    return;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError;
  }
};

export const deleteAsset = async (opts: {
  asset_id: number;
  commit: boolean;
  soft_delete?: boolean;
}) => {
  opts.soft_delete = true;
  try {
    const data = await apiRequest(
      HTTP_METHOD.DELETE,
      API_TYPE.FILTER_API,
      `/admin/assets/movies`,
      opts
    );
    if (opts.commit === false && data.asset_id === opts.asset_id) {
      return { canDelete: true };
    } else return { success: data.deletedMovie === 1 };
  } catch (error) {
    console.error("Error deleting asset:", error);
    return { canDelete: false };
  }
};

export const copyMovie = async (data: {
  destination_platform_id: number;
  source_movie_asset_id: number;
  name_suffix?: string;
  video_id_suffix?: string;
}) => {
  try {
    const response = await apiRequest(
      HTTP_METHOD.POST,
      API_TYPE.FILTER_API,
      "/admin/assets/movies/copy",
      data
    );

    return response
  } catch (error) {
    console.error("Error copying movie:", error);
    return { success: false };
  }
};
