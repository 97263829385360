import { getSocialUserType } from '../../utils/userUtil'
import { User } from './userTypes'

export enum UserStatus {
  Active = "Active",
  ActiveCanceling = "Active-Canceling",
  CanceledNoTrial = "Canceled-NoTrial",
  CanceledTrialAvailable = "Canceled-TrialAvailable"
}

const getStatus = (active: boolean = false, cancelAtPeriodEnd: boolean = false, trialAvailable: boolean = false): string => {
  if (active) {
    return cancelAtPeriodEnd ? "Active-Canceling" : "Active";
  }
  return trialAvailable ? "Canceled-TrialAvailable" : "Canceled-NoTrial";
};

export const ConstructQueryUser = async(data: any): Promise<User> => {
  const user: User = {
    expMonth: data?.stripe?.card.expMonth,
    expYear: data?.stripe?.card.expYear,
    last4: data?.stripe?.card.last4,
    brand: data?.stripe?.card.brand,
    firstName: data?.user?.given_name,
    lastName: data?.user?.family_name,
    email: data?.user?.email,
    cancelAtPeriodEnd: data?.stripe?.cancelAtPeriodEnd,
    subscription: data?.stripe?.subscriptionId,
    periodEndDate: data?.stripe?.periodEndDate ? data?.stripe?.periodEndDate : null,
    loginType: getSocialUserType(data?.user.user_id),
    canceled: data?.stripe?.cancelAtPeriodEnd,
    user_id: data?.user?.user_id,
    nextBilling: data?.stripe?.nextBillingDate,
    substatus: data?.stripe?.statuses[0],
    created_at: data?.user?.created_at,
    phone: data?.user?.user_metadata?.contact?.phone || '' ,
    status: getStatus(data?.stripe?.active, data?.stripe?.cancelAtPeriodEnd, data.stripe.trialAvailable),
    isActive: data.stripe?.active,
    hasStripe: data?.user?.app_metadata?.stripe?.id ? true : false,
    stripeId: data?.user?.app_metadata?.stripe?.id,
    amount: data?.stripe?.nextBillingAmount,
    interval: formatInterval(data?.stripe?.interval),
    discounted: data.stripe?.discounted,
    testMode: data.stripe?.testMode,
    churnkeyHash: data.stripe?.churnkeyHash,
    activeCouponMessage: data.stripe?.activeCouponMessage,
    trialAvailable: data.stripe?.trialAvailable,
    app_metadata: data.user.app_metadata

  }
  return user
}

const formatInterval = (interval: string) => {
  if (interval === 'year') {
    return 'Annual '
  } else return 'Monthly'
}