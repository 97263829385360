import * as React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useGroupBy,
  useExpanded,
  useFilters,
  TableOptions,
  Column,
} from "react-table";
import CustomCell from "./CustomCell";
import "./CustomTable.styles";
import {
  StyledTable,
  StyledThead,
  Tcell,
  StyledTrow,
  Pagination,
  Item,
  PageButton,
  PaginationDropDown,
  PageInput,
  TableFoot,
  ToolBarSpacer,
} from "./CustomTable.styles";
import { TableToolbar, wrapTextSignal } from "./TableToolBar";
import {
  LocalStorage,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorageService";

import { adminAppStateSignal } from "../../app/store";
import { signal } from "@preact/signals-react";
import { JSX } from "react/jsx-runtime";

export const tableInstanceSignal = signal<any>(null);
export const slidingRowsSignal = signal(new Set());

interface CustomTableProps<T extends Record<string, unknown>>
  extends TableOptions<T> {
  columns: Column<T>[];
  data: T[];
  updateMyData?: (rowIndex: number, columnId: string, value: any) => void;
  skipPageReset?: boolean;
  scoresToChange?: Record<string, any>;
  setScoresToChange?: React.Dispatch<React.SetStateAction<any>>;
  selectedTable?: any;
  toggleDrawer?: () => void;
  hidden: string[];
  sortedBy: {
    desc: boolean;
    id: string;
  }[];
  hideSpacer?: boolean;
}

const hooks = [
  useGlobalFilter,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  usePagination,
];

const CustomTable: React.FC<React.PropsWithChildren<CustomTableProps<any>>> = ({
  columns,
  data,
  updateMyData,
  skipPageReset = false,
  scoresToChange,
  setScoresToChange,
  selectedTable,
  toggleDrawer,
  hidden,
  sortedBy = [],
  hideSpacer = false,
}) => {
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(hidden);
  const toolbarRef = React.useRef<HTMLDivElement>(null);
  const [toolbarHeight, setToolbarHeight] = React.useState(0);
  const filterToggle = adminAppStateSignal.value.filterToggle;
  const defaultColumn = React.useMemo<Partial<Column<any>>>(
    () => ({
      disableFilter: true,
      Filter: <></>,
      Cell: CustomCell,
      minWidth: 30,
      width: 150,
      maxWidth: 200,
    }),
    []
  );

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
        pageIndex: 0,
        hiddenColumns: hidden,
        sortBy: sortedBy,
      } as any,
      defaultColumn,
      autoResetPage: !skipPageReset,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: !skipPageReset,
      autoResetSortBy: false,
      autoResetRowState: !skipPageReset,
      autoResetGlobalFilter: false,
      setHiddenColumns,
      updateMyData,
      selectedTable,
      scoresToChange,
      setScoresToChange,
      toggleDrawer,
    } as any,
    ...hooks
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setGroupBy,

    setFilter,
    state: { pageIndex, pageSize, groupBy },
  }: any = instance;

  // Sync table instance with signal
  React.useEffect(() => {
    tableInstanceSignal.value = instance;
  }, [instance]);

  // Save and retrieve user page size
  const saveUserPageSize = React.useCallback(
    (pageSize: number) => {
      setLocalStorageItem(
        LocalStorage.CLEARPLAY_PAGE_PREF,
        pageSize.toString()
      );
      setPageSize(pageSize);
    },
    [setPageSize]
  );

  const getUserPageSize = React.useCallback(() => {
    const pageSize = getLocalStorageItem(LocalStorage.CLEARPLAY_PAGE_PREF);
    setPageSize(pageSize ? Number(pageSize) : 25);
  }, [setPageSize]);

  // Update hidden columns if `hidden` prop changes
  React.useEffect(() => {
    setHiddenColumns(hidden);
  }, [hidden]);

  // Apply filters based on memoized filterToggle
  React.useEffect(() => {
    if (!headerGroups.length || !data.length) return;

    const availableColumnIds = headerGroups
      .flatMap((group: { headers: any }) => group.headers || [])
      .map((column: { id: any }) => column.id);

    Object.entries(filterToggle).forEach(([key, value]) => {
      if (availableColumnIds.includes(key)) {
        setFilter(key, value.isChecked ? true : undefined);
      }
    });
  }, [filterToggle, setFilter, headerGroups, data]);

  // Calculate and update toolbar height
  const updateToolbarHeight = React.useCallback(() => {
    if (toolbarRef.current) {
      setToolbarHeight(toolbarRef.current.offsetHeight);
    }
  }, []);

  React.useLayoutEffect(() => {
    updateToolbarHeight();

    const handleResize = () => {
      updateToolbarHeight();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateToolbarHeight]);

  // Load user preferences on table load
  React.useEffect(() => {
    getUserPageSize();
    gotoPage(0);
  }, [selectedTable, getUserPageSize, gotoPage]);

  if (!data.length) return <></>;

  return (
    <>
      <ToolBarSpacer
        height={toolbarHeight}
        hideSpacer={hideSpacer.toString()}
      />
      <TableToolbar
        toolBarRef={toolbarRef}
        instance={instance}
        {...{
          setGlobalFilter,
          setGroupBy,
          selectedTable,
          groupBy,
          setHiddenColumns,
        }}
      />
      <StyledTable {...getTableProps()}>
        <StyledThead>
          {headerGroups && headerGroups.length > 0 ? (
            headerGroups.map((group: any) => (
              <StyledTrow
                {...(group.getHeaderGroupProps
                  ? group.getHeaderGroupProps()
                  : {})}
              >
                {group.headers?.map((column: any, index: number) => (
                  <Tcell
                    key={index}
                    {...(column.getHeaderProps
                      ? column.getHeaderProps(
                          column.getSortByToggleProps?.() || {}
                        )
                      : {})}
                    className={index === 0 ? "top-sticky-cell" : ""}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ▼"
                          : " ▲"
                        : ""}
                    </span>
                  </Tcell>
                ))}
              </StyledTrow>
            ))
          ) : (
            <StyledTrow>
              <Tcell colSpan={columns.length || 1}>No headers available</Tcell>
            </StyledTrow>
          )}
        </StyledThead>
        <tbody {...getTableBodyProps()}>
          {page.map(
            (row: {
              index: number
              getRowProps: () => any;
              cells: any[];
              getToggleRowExpandedProps: () => JSX.IntrinsicAttributes &
                React.ClassAttributes<HTMLSpanElement> &
                React.HTMLAttributes<HTMLSpanElement>;
              isExpanded: any;
              subRows: string | any[];
              original: any
            }) => {
              prepareRow(row);
              return (
                <StyledTrow {...row.getRowProps()}
                className={slidingRowsSignal.value.has(row.index) ? "slide-out" : ""}
                >
                  {row.cells.map(
                    (
                      cell: {
                        getCellProps: () => any;
                        column: { id: string };
                        isGrouped: any;
                        render: (arg0: string) => any;
                        isAggregated: any;
                        isPlaceholder: any;
                      },
                      index: number
                    ) => (
                      <Tcell
                        key={index}
                        {...cell.getCellProps()}
                        className={
                          cell.column.id === "comment"
                            ? "comments-column"
                            : index === 0
                            ? "sticky-cell grouped"
                            : "grouped"
                        }
                        isWrap={wrapTextSignal.value ?? false}
                      >
                        {cell.isGrouped ? (
                          <>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? "🔽" : "⏩"}
                            </span>{" "}
                            {cell.render("Cell")} ({row.subRows.length || 0})
                          </>
                        ) : cell.isAggregated ? (
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : (
                          cell.render("Cell")
                        )}
                      </Tcell>
                    )
                  )}
                </StyledTrow>
              );
            }
          )}
        </tbody>
        <TableFoot>
          <tr>
            <td>
              Showing:&nbsp;{page.length}&nbsp;of&nbsp;{data.length}
            </td>
          </tr>
        </TableFoot>
      </StyledTable>
      <Pagination>
        <PageButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </PageButton>
        <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </PageButton>
        <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </PageButton>
        <PageButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </PageButton>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span>
          | Go to page:{" "}
          <PageInput
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e: { target: { value: any } }) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
        <PaginationDropDown
          value={pageSize}
          onChange={(e: { target: { value: any } }) =>
            saveUserPageSize(Number(e.target.value))
          }
        >
          {[10, 25, 50, 100, data.length].map((size) => (
            <Item key={size} value={size}>
              Show {size}
            </Item>
          ))}
        </PaginationDropDown>
      </Pagination>
    </>
  );
};

export default CustomTable;
