import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Flix } from "../../app/app.types";
import { ContentLoading } from "../../theme";
import PulseLoader from "react-spinners/PulseLoader";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { ScrollingRows, TabPanel } from "./TabContent.styles";
import { SliderRow } from "./shelfComponents/SliderRow";
import { filteringDownProvider } from "../../admin/utils";
import { Platform } from "../adminFeatures/tables/tableTypes";
import { hasNextPageSignal, isFetchingNextPageSignal, useFlix } from "../flix/useFlix";
import { syntheticKeyEvent } from "./shelfComponents/rowUtil";
import {
  AssetList,
} from "../adminFeatures/assets/assetTypes";
import AlgoliaSearchWrapper from "../../pages/AlgoliaSearchWrapper";
import { userDeviceSiganl } from "../user/userSignals";
import { DeviceType } from "../user/userTypes";
interface TabPanelProps {
  index: number;
  value: number;
  provider: number;
  loggedIn: boolean;
  filteringDown: Platform[] | undefined;
}
const TabContent: React.FC<TabPanelProps> = (props) => {
  const { ref, focusKey  } = useFocusable({});
  const { value, index, provider, loggedIn, filteringDown } = props;
  const { initFlixData, fetchNextPage } =useFlix();
  
  const flixDataRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
    const isMobile = userDeviceSiganl.value !== DeviceType.DESKTOP;
  const isMobileSearch = window.location.pathname.includes("search") && isMobile

  const handleScroll = useCallback(() => {
    const totalHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrolledFromTop = window.scrollY;
    const remainingScrollHeight = totalHeight - scrolledFromTop - windowHeight;
    const thirtyPercentOfTotalHeight = totalHeight * 0.3;
  
    if (
      remainingScrollHeight <= thirtyPercentOfTotalHeight &&
      hasNextPageSignal.value &&
      !isFetchingNextPageSignal.value
    ) {
      if (provider === 100) return;
      fetchNextPage();
    }
  }, [fetchNextPage, provider]);
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (isFetchingNextPageSignal.value) return;
    if (!initFlixData?.pageParams || initFlixData?.pageParams?.length === 1) return;
  
    if (initFlixData?.pageParams?.length !== flixDataRef.current) {
      flixDataRef.current = initFlixData?.pageParams?.length;
      setTimeout(() => {
        requestAnimationFrame(() => {
          syntheticKeyEvent(38);
          syntheticKeyEvent(40);
        });
      }, 100);
    }
  }, [initFlixData?.pageParams, initFlixData?.pages.length]);

  const quickload = useMemo(() => {
    return [
      { title: "", isLoader: true, films: [{}, {}, {}, {}, {}, {}] as Flix[] },
      { title: "", isLoader: true, films: [{}, {}, {}, {}, {}, {}] as Flix[] },
      { title: "", isLoader: true, films: [{}, {}, {}, {}, {}, {}] as Flix[] },
    ] as any;
  }, []);

  const fastLoad = useMemo(() => {
    return initFlixData?.pages?.length ? initFlixData.pages : quickload;
  }, [initFlixData?.pages]);

  return (
    <FocusContext.Provider value={focusKey}>
      <TabPanel
        style={isMobileSearch ? { paddingTop: "170px" } : {}}
        loggedin={loggedIn.toString()}
        role="tabpanel"
        messageActive={
          filteringDownProvider(filteringDown, provider)?.web_banner
        }
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        ref={scrollRef}
      >
        {index === 100 && <AlgoliaSearchWrapper/>}
        {/* {showFeatured && provider === 7 && <ContentShelf provider={provider} />} */}
        {fastLoad?.length && (
          <ScrollingRows ref={ref}>
            {fastLoad?.map((shelf: AssetList, shelfIndex: number) => (
              <SliderRow
                key={`${shelf.name}-${shelfIndex}-${provider}`}
                shelf={shelf}
                value={value}
                index={shelfIndex}
                provider={provider}
              />
            ))}
          </ScrollingRows>
        )}
        <ContentLoading
          active={isFetchingNextPageSignal.value}
          spinner={<PulseLoader color={"#66ccff"} />}
        />
      </TabPanel>
    </FocusContext.Provider>
  );
};

export { TabContent };
