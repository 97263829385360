import React from "react";
import { Redirect } from "react-router-dom";

import RingLoader from "react-spinners/RingLoader";
import { Queries, useTypedQueryData } from "../lib/react-query";
import { AppLoading } from "../theme";
import { AppRoutes } from "../app/routes";
import Home from "../pages/Home";
import { useIsFetching } from "@tanstack/react-query";

const HomeRedirector: React.FC = (props) => {
  const userLoading = useIsFetching([Queries.FETCH_ME])
  const user = useTypedQueryData(Queries.FETCH_ME)


  if (userLoading) {
    return (
      <AppLoading active spinner={<RingLoader color={"#66ccff"} />} />
    );
  }

  if (!user) {
    // If no user, redirect to the JoinClearplay page
    return <Redirect to={AppRoutes.JOIN_CLEARPLAY} />;
  }

  // If the user is logged in, render the Home component
  return <Home {...props} />;
};

export default HomeRedirector;