import { useEffect, useRef, useState, forwardRef } from "react";
import Search from "../../assets/images/mobile-search.svg";
import Menu from "../../assets/images/menu.svg";
import useHandleChange from "../../hooks/ProviderTabComponentHooks";
import { displayProviderMap } from "../../app/app.types";
import "./MobileProviderMenu.scss";
import { flixProviderSignal } from "../flix/useFlix";

interface MobileProviderMenuProps {
  providerTab: number;
}

const MobileProviderMenu: React.FC<MobileProviderMenuProps> = ({ providerTab }) => {
  const { handleChange } = useHandleChange();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const HamburgerIcon = () => (
    <img src={Menu} alt="Menu" style={{ width: 24, height: 24 }} />
  );

  const SearchIcon = () => (
    <img
      src={Search}
      alt="Search"
      style={{
        width: 24,
        height: 24,
        filter: providerTab === 5 ? "brightness(0) invert(1)" : "",
      }}
    />
  );

  // Dropdown Menu Component
  const DropdownMenu = forwardRef<HTMLDivElement, { 
    isOpen: boolean;
    providers: string[];
    onSelect?: (index: number) => void;
  }>(({ isOpen, providers, onSelect }, ref) => {
    if (!isOpen) return null;

    return (
      <div ref={ref} className="provider-dropdown">
        {providers.map((provider, index) => (
          <div
            className="mobile-provider"
            key={index}
            onClick={() => {
              if (onSelect) {
                onSelect(index);
              }
            }}
          >
            {provider}
          </div>
        ))}
      </div>
    );
  });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isMenuOpen &&
        menuRef.current &&
        event.target instanceof Node &&
        !menuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div id="mobile-tabs">
      <div className="selected-wrapper">
        <div className="mobile-selected">
          {displayProviderMap[flixProviderSignal.value]}
        </div>
      </div>

      {/* Dropdown Menu */}
      <div ref={menuRef}>
        <DropdownMenu
          isOpen={isMenuOpen}
          providers={["Amazon", "Apple TV+", "Disney+", "Max", "Netflix"]}
          onSelect={(index) => {
            setIsMenuOpen(false);
            handleChange(index);
          }}
        />
      </div>

      {/* Menu Button */}
      <div className="icon" onClick={() => setIsMenuOpen((prev) => !prev)}>
        <HamburgerIcon />
      </div>

      {/* Search Button */}
      <div className="icon" onClick={() => handleChange(5)}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default MobileProviderMenu;